<template>
  <div class="one-column-width">
    <!-- show action result -->
    <v-snackbar v-model="snackbar" absolute top right color="success">
      <span>{{ snackBarText }}</span>
      <v-icon dark>mdi-checkbox-marked-circle</v-icon>
    </v-snackbar>

    <v-snackbar v-model="errorMessage" absolute top right color="danger">
      <span>Something went RONG, Please Try Again!</span>
      <v-icon dark>mdi-checkbox-marked-circle</v-icon>
    </v-snackbar>

    <!-- Confirm Delete Item -->

    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="380px">
        <v-card>
          <v-card-title>
            <span class="headline"
              >Do you really want to DELETE this item?</span
            >
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="custom-submit-button" text @click="dialog = false"
              >No</v-btn
            >
            <v-btn class="custom-cancel-button" text @click="deleteItem"
              ><b-spinner small type="grow" v-if="deleting"></b-spinner
              >Delete</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- table start -->
    <v-card>
      <v-card-title>
        Listings
        <div class="my-2 ml-2">
          <v-btn
            class="
              btn btn-sm btn-success
              font-weight-bold
              py-2
              px-3 px-xxl-5
              my-1
            "
            fab
            small
            @click="redirectToAddListing()"
          >
            <v-icon dark>mdi-plus</v-icon>
          </v-btn>
        </div>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search Listings"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="tableData"
        :search="search"
        loading
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            class="
              btn btn-sm btn-success
              font-weight-bold
              py-2
              px-3 px-xxl-5
              my-1
            "
            fab
            small
            @click="redirectToEditListing(item)"
          >
            <v-icon dark>mdi-pencil</v-icon>
          </v-btn>
          &nbsp;
          <v-btn
            class="
              btn btn-sm btn-danger
              font-weight-bold
              py-2
              px-3 px-xxl-5
              my-1
            "
            fab
            small
            @click="askUserAboutDelete(item)"
          >
            <v-icon dark>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <!-- table end -->
  </div>
</template>

<script>
import ProgramService from "@/MainServices/ProgramService.js";
import SubjectService from "@/MainServices/SubjectService.js";
import ListingService from "@/MainServices/ListingService.js";

import { required, minLength } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      form: [{ name: "", program_id: null }],
      programs: [],
      editId: null,
      deleteId: null,
      dialog: false,
      rules: {
        name: [(val) => (val || "").length > 0 || "Name is required"],
        program_id: [(val) => !!val || "Program is required"],
      },
      snackbar: false,
      snackBarText: null,
      submitting: false,
      deleting: false,
      errorMessage: false,
      search: "",
      showFormData: false,
      headers: [
        {
          text: "Campus",
          align: "left",
          sortable: false,
          value: "campus",
          width: "20%",
        },
        {
          text: "Department",
          align: "left",
          sortable: false,
          value: "department",
          width: "18%",
        },

        {
          text: "Program",
          align: "left",
          sortable: false,
          value: "program",
          width: "15%",
        },
        {
          text: "Title",
          align: "left",
          sortable: false,
          value: "title",
          width: "20%",
        },
        {
          text: "Plan",
          align: "left",
          sortable: false,
          value: "stripe_plan",
          width: "10%",
        },
        { text: "Actions", value: "actions", sortable: false },
      ],
      tableData: [],
    };
  },
  validations: {
    form: {
      required,
      $each: {
        name: { minLength: minLength(1), required },
        program_id: { minLength: minLength(1), required },
      },
    },
  },
  components: {},

  computed: {},

  methods: {
    getInititalData() {
      ListingService.getInstituteListings()
        .then((res) => {
          this.tableData = res.data;
        })

        .catch(() => {
          this.loading = false;
        });
    },
    // main methods
    redirectToAddListing() {
      this.$router.push("/institute/add-listing");
    },
    redirectToEditListing(item) {
      localStorage.setItem("listing", JSON.stringify(item));
      this.$router.push({
        name: "institute-edit-listing",
        params: { item: item },
      });
    },
    showForm() {
      this.editId = null;
      this.showFormData = true;
      this.resetForm();
    },
    showEditForm(item) {
      this.form = [{ name: item.subject, program_id: item.program_id }];
      this.editId = item.id;
      this.showFormData = true;
    },
    cancelForm() {
      this.resetForm();

      this.showFormData = false;
    },
    resetForm() {
      this.form = [{ city: "", country_id: null }];
      this.editId = null;
      setTimeout(() => {
        this.$refs.form.reset();
      }, 100);
    },
    addNewRow() {
      this.form.push({ city: "", country_id: null });
    },
    deleteRow(index) {
      this.form.splice(index, 1);
    },
    submit() {
      this.submitting = true;
      if (this.editId) {
        SubjectService.editSubject(this.form, this.editId)
          .then((res) => {
            this.resetForm();
            this.getInititalData();
            this.submitting = false;
            this.snackBarText = "City Updated successful!";
            this.snackbar = true;
          })
          .catch((error) => {
            this.errorMessage = true;
          });
      } else {
        SubjectService.addNewSubjects(this.form)
          .then((res) => {
            this.resetForm();
            this.getInititalData();
            this.submitting = false;
            this.snackBarText = "Subject Added successful!";
            this.snackbar = true;
          })
          .catch((error) => {
            this.errorMessage = true;
          });
      }
    },
    askUserAboutDelete(item) {
      this.deleteId = item.id;
      this.dialog = true;
    },
    deleteItem() {
      this.deleting = true;
      SubjectService.deleteSubject(this.deleteId)
        .then((res) => {
          this.editId = null;
          this.deleting = false;
          this.dialog = false;
          this.resetForm();
          this.getInititalData();
          this.snackBarText = "Subject Deleted successful!";
          this.snackbar = true;
        })
        .catch((error) => {
          this.errorMessage = true;
          this.dialog = false;
        });
    },
  },
  mounted() {
    this.getInititalData();
    ProgramService.getInstitutePrograms()
      .then((res) => {
        res.data.map((el) => {
          el.departments.map((el2) => {
            el2.programs.map((el3) => {
              this.programs.push({ value: el3.id, text: el3.program });
            });
          });
        });
      })
      .catch(() => {
        this.loading = false;
      });
  },
};
</script>
